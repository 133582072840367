<template>
    <div class="GoBackToHomePage hidden-over-sm" id="GoBackToHomePageBtn">
        <img @click="$router.push('/')" src="@/assets/images/logo/reklameskaerm_big.png" />
    </div>
    <div class="">
    <el-row class="banner-message" v-if="banner !== null">
            <div class="banner">
                <div class="banner-text" v-html="banner"></div>
                <div class="banner-close" @click="closeBanner()">X</div>
            </div>
        </el-row>
    </div>
    <div class="header hidden-sm" :class="{bannerSpacer: banner !== null}">
        <el-row class="hidden-sm" :gutter="12">
            <el-col :xs="12" :sm="8" :md="8" :lg="9" :xl="9">
                <div class="logo-container">
                        <img @click="$router.push('/')" src="@/assets/images/logo/reklameskaerm_big.png" alt="reklameskaerm.dk"/>
                </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="6" :xl="6">
                <div class="nav-links">
                    <el-menu :default-active="activeIndex0" class="el-menu" mode="horizontal" @select="handleSelect">
                    <el-menu-item style="width: 50%; text-align: center;" @click="$router.push('/reklameskaerme')" index="1">Bookingportal</el-menu-item>
                    <el-menu-item style="width: 50%; text-align: center;"  @click="$router.push('/faa-jeres-egen-reklameskaerm')" index="2">Køb reklameskærm</el-menu-item>
                    </el-menu>
                </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="9" :xl="9">
                <div class="account-buttons">
                    <el-button id="createUserButton" v-if="!isUserLoggedIn" @click="openCreateUserdialog">Opret bruger</el-button>
                    <el-button id="login-button" v-if="!isUserLoggedIn" @click="loginDialogVisible = true">Log ind</el-button>
                    <el-button v-if="itemsInShoppingCart > 0" @click="$router.push('/kurv')">Kurv ({{ itemsInShoppingCart }})</el-button>
                    <el-button @click="$router.push('/min-konto')" v-if="isUserLoggedIn">Min konto</el-button>
                    <el-button v-if="isUserLoggedIn" @click="logout">Log ud</el-button>
                </div>
            </el-col>
        </el-row>
    </div>

    <!-- Login dialog -->
    <el-dialog 
    title="Log ind" 
    v-model="loginDialogVisible" 
    width="370px"
    destroy-on-close
    >
        <el-form :model="loginForm" >
            <el-form-item>
                <el-input type="email" v-model="loginForm.email" placeholder="Email">
                    <template #prepend><i class="el-icon-user"></i></template>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-input type="password" v-model="loginForm.password" @keyup.enter="login" placeholder="Kodeord" show-password>
                    <template #prepend><i class="el-icon-lock"></i></template>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button :loading="loginForm.loading" class="login-button" @click="login">Login</el-button>
            </el-form-item>
        </el-form>
        <div class="login-footer">
            <span v-if="loginForm.showLoginFailed" class="error-msg">Email eller adgangskode er forkert. Prøv igen.</span>
            <div class="login-dialog-links">
                <span @click="forgotPasswordDialogVisible = true; loginDialogVisible = false;">Glemt dit kodeord?</span> | <span @click="createUserDialogVisible = true; loginDialogVisible = false;">Opret bruger</span>
            </div>
        </div>
    </el-dialog> 

    <!-- Create user dialog -->
    <el-dialog 
    title="Opret ny bruger" 
    v-model="createUserDialogVisible" 
    width="400px"
    destroy-on-close
    >
        <el-form :model="loginForm" id="createUserForm">
            <el-form-item>
                <el-input type="text" v-model="createUserForm.name" placeholder="Fulde navn">
                    <!-- <template #prepend>Fulde navn</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showNameError">Navn ugyldig</span>
            </el-form-item>
            <el-form-item>
                <el-checkbox v-model="createUserForm.asPrivate" /> Opret bruger som privatperson
            </el-form-item>
            <el-form-item v-if="!createUserForm.asPrivate">
                <el-input type="text" v-model="createUserForm.companyName" placeholder="Virksomhedens navn">
                    <!-- <template #prepend>Virksomhedsnavn</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showCompanyNameError">Virksomhedens navn ugyldig</span>
            </el-form-item>
            <el-form-item v-if="!createUserForm.asPrivate">
                <el-input type="number" v-model="createUserForm.cvr" placeholder="CVR nr.">
                    <!-- <template #prepend>CVR</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showCVRError">CVR nr. ugyldig</span>
            </el-form-item>
            <el-form-item>
                
                <el-input type="text" placeholder="Vejnavn og hus nr." id="dawa-autocomplete-input" v-model="createUserForm.streetnameAndNumber">
                    <!-- <template #prepend>Vejnavn og hus nr.</template> -->
                </el-input>
                <div class="autocomplete-container">
                </div>
                <span class="input-error" v-if="createUserFormValidation.showStreetnameAndNumberError">Feltet skal udfyldes</span> 
            </el-form-item>
            <el-form-item>
                <el-input type="number" placeholder="Postnummer" id="zipCode" v-model="createUserForm.zipCode">
                    <!-- <template #prepend>Postnummer</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showZipCodeError">Feltet skal udfyldes og indeholde 4 tegn</span>
            </el-form-item>
            <el-form-item>
                <el-input type="text" placeholder="By" id="city" v-model="createUserForm.city">
                    <!-- <template #prepend>By</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showCityError">Feltet skal udfyldes</span>
            </el-form-item>
            <el-form-item>
                <el-input type="email" v-model="createUserForm.emailAddress" placeholder="Email">
                    <!-- <template #prepend>Email</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showEmailAddressError">Email ugyldig</span>
            </el-form-item>
            <div style="padding-bottom: 20px;">
                <el-checkbox v-model="createUserForm.useBillingEmail" /> Brug en anden email til modtagelse af faktura.
            </div>
            <el-form-item v-if="createUserForm.useBillingEmail">
                <el-input type="email" v-model="createUserForm.billingEmail" placeholder="Faktura email">
                    <!-- <template #prepend>Fakutra email</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showBillingAddressError">Faktura email ugyldig</span>
            </el-form-item>
            <el-form-item>
                <el-input type="number" v-model="createUserForm.phone" placeholder="Telefon nr.">
                    <!-- <template #prepend>Telefon nr.</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showPhoneError">Telefon nr. ugyldig</span>
            </el-form-item>
            <el-form-item>
                <el-input type="password" v-model="createUserForm.password" placeholder="Adgangskode" show-password>
                    <!-- <template #prepend>Adgangskode</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showPasswordError && !createUserFormValidation.showPasswordMisMatchError">Adgangskode ugyldig</span>
                <span class="input-error" v-if="createUserFormValidation.showPasswordMisMatchError">Adgangskode matcher ikke</span>
                <span class="input-info">Adgangskode skal indeholde mindst 8 tegn og tal</span>
            </el-form-item>
             <el-form-item>
                <el-input type="password" v-model="createUserForm.confirmPassword" placeholder="Adgangskode igen" show-password>
                    <!-- <template #prepend>adgangskode igen</template> -->
                </el-input>
                <span class="input-error" v-if="createUserFormValidation.showPasswordConfirmError">Adgangskode igen ugyldigt</span>
            </el-form-item>
            <div style="padding-bottom: 20px;">
                <div style="padding-bottom: 10px;">
                    <el-checkbox v-model="createUserForm.conditionsAccepted" required /> Jeg har læst og accepteret <span class="linkToAgreement" @click="$router.push('/hjaelp?goTo=Handelsbetingelser'); createUserDialogVisible=false">handelsbetingelser</span>.
                </div>
                <div>
                    <el-checkbox v-model="createUserForm.signupForNewsletter" /> Jeg ønsker at modtage nyhedsbrev
                </div>
            </div>
            <el-form-item>
                <span class="input-error" v-if="createUserFormValidation.shopwConditionsAcceptedError">Du skal godkende vores handelbetingelser før vi kan oprette din bruger.</span>
                <el-button :loading="createUserForm.loading" class="login-button" @click="createUser">Opret bruger</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>   

    <!-- Forgot password dialog -->
    <el-dialog 
    title="Glemt kodeord" 
    v-model="forgotPasswordDialogVisible" 
    width="30%"
    destroy-on-close
    >
        <div v-if="!forgotPasswordForm.hideInput">
            <span style="word-break: break-word;">Hvis du har glemt din adgangskode, kan du herunder skrive din email, hvorefter du vil modtage et link til nulstilling af kodeord.</span>
            <el-form :model="forgotPasswordForm" style="margin-top: 20px;">
                <el-form-item>
                    <el-input type="email" v-model="forgotPasswordForm.email" placeholder="Email">
                        <template #prepend><i class="el-icon-user"></i></template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="login-button" @click="sendForgotPasswordEmail">Send email</el-button>
                </el-form-item>
            </el-form>
        </div>

        <span v-if="forgotPasswordForm.hideInput" style="word-break: break-word;">En email er nu sendt til {{ forgotPasswordForm.email }}, med link til nulstilling af kodeord. <br /><br />Har du ikke modtaget en email, bedes du tjekke dit spamfilter og sikre dig at den indtastede email eksistere på en aktiv konto.</span>
    </el-dialog>

    <div class="phoneNavigation hidden-over-sm">
        <div class="hoverMenu" v-if="hoverMenuToggled" v-click-outside="hideHoverMenuButton">
            <div v-if="itemsInShoppingCart > 0" class="border-under" @click="$router.push('/kurv'); hideHoverMenuButton()">Kurv ({{ itemsInShoppingCart }})</div>
            <div class="border-under" @click="$router.push('/min-konto'); hideHoverMenuButton()">Min konto</div>
            <div @click="logout(); hideHoverMenuButton()">Log ud</div>
        </div>
        <el-row>
            <el-col :xs="3" :sm="3"></el-col>
            <el-col class="navigation" :xs="6" :sm="6">
                <el-link href="/reklameskaerme" class="phoneNavigationLink">
                    <div class="phoneNavigationLinkIcon"><i class="el-icon-search"></i></div>
                    <div class="phoneNavigationLinkText">Udforsk</div>
                </el-link>
            </el-col>
            <el-col  class="navigation login" :xs="6" :sm="6">
                <div v-if="!isUserLoggedIn" @click="loginDialogVisible = true">
                    <div class="phoneNavigationLinkIcon"><i class="el-icon-user"></i></div>
                    <div class="phoneNavigationLinkText">Login</div>
                </div>
                <div v-if="isUserLoggedIn" @click="hoverMenuToggled = true">
                    <div class="phoneNavigationLinkIcon"><i class="el-icon-menu"></i></div>
                    <div class="phoneNavigationLinkText">Menu</div>
                </div>
            </el-col>
            <el-col class="navigation" :xs="6" :sm="6">
                <el-link href="/kurv" class="phoneNavigationLink">
                <div class="phoneNavigationLinkIcon"><i class="el-icon-shopping-cart-1"></i></div>
                <div class="phoneNavigationLinkText">Kurv <span v-if="itemsInShoppingCart > 0">({{ itemsInShoppingCart }})</span></div>
                </el-link>
            </el-col>
            <el-col :xs="3" :sm="3"></el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios';
import { ElLoading } from 'element-plus';
import $ from 'jquery';
import vClickOutside from 'click-outside-vue3';
import qs from 'qs';

export default {
  name: 'Header',
  directives: {
      clickOutsite: vClickOutside.directive
  },
  mounted() {
    this.itemsInShoppingCart = this.$store.getters.getCartCount;
    this.$store.subscribe(() => {
        this.itemsInShoppingCart = this.$store.getters.getCartCount;
    });
    this.getBanner();
  },
  watch: {
        '$route' () {
            $('#GoBackToHomePageBtn').css('display', '');
        },     
    },
  data() {
      return {
          loginDialogVisible: false,
          createUserDialogVisible: false,
          forgotPasswordDialogVisible: false,
          banner: null,
          loginForm: {
              email: '',
              password: '',
              showLoginFailed: false,
              loading: false,
          },
          createUserForm: {
              name: '',
              companyName: '',
              cvr: '',
              emailAddress: '',
              billingEmail: '',
              phone: '',
              password: '',
              confirmPassword: '',
              useBillingEmail: false,
              asPrivate: false,
              conditionsAccepted: false,
              signupForNewsletter: false,
              loading: false,
              streetnameAndNumber: '',
              zipCode: '',
              city: '',
          },
          createUserFormValidation: {
              showNameError: false,
              showCompanyNameError: false,
              showCVRError: false,
              showEmailAddressError: false,
              showBillingAddressError: false,
              showPhoneError: false,
              showPasswordError: false,
              showPasswordConfirmError: false,
              showPasswordMisMatchError: false,
              shopwConditionsAcceptedError: false,
              showStreetnameAndNumberError: false,
              showZipCodeError: false,
              showCityError: false
          },
          forgotPasswordForm: {
              email: '',
              hideInput: false
          },
          itemsInShoppingCart: 0,
          hoverMenuToggled: false
      }
  },
  computed: {
      isUserLoggedIn() {
          this.detectLoginExpire();
          return this.$store.getters.isLoggedIn;
      }
  },
  methods: {
      hideHoverMenuButton() {
          this.hoverMenuToggled = false;
      },
      createLoader(fullScreen, lockedScreen, text) {
          return ElLoading.service({ fullscreen: fullScreen, lock: lockedScreen, text: text})
      },
      login() {
        //   var loading = this.createLoader(true, true)
        this.loginForm.loading = true;
          axios.post(`${process.env.VUE_APP_BACKEND_URL}Auth/Login`, {
              "email": this.loginForm.email,
              "password": this.loginForm.password
          })
          .then(response => {
              var data = response.data; 

              this.closeCreateDialog();
              this.createUserForm.loading = false;

              if(data.isSuccess)
              {
                  localStorage.setItem('userTimeout', data.expireDate);
                  localStorage.setItem('token', data.message);
                  this.closeLoginDialog()
                  this.loginForm.showLoginFailed = false;
                  this.setUserInfoData();
                  this.loginForm.loading = false;
                //   loading.close();
              }
          }).catch(() => {
            //   loading.close();
              this.loginForm.loading = false;
              this.loginForm.showLoginFailed = true;
          })
      },
      logout() {
          this.hideHoverMenuButton();
          this.$store.commit('setAuthUser', null);
          this.$router.push('/');
      },
      detectLoginExpire() {
          var userTimeout = localStorage.getItem('userTimeout');
          if(userTimeout !== null) {
            var currentTime = new Date();
            var userTimeoutConverted = new Date(Date.parse(userTimeout));
            if(currentTime > userTimeoutConverted) {
                this.logout();
            }
          }
      },
      setUserInfoData() {
          axios.get(`${process.env.VUE_APP_BACKEND_URL}Auth/GetCurrentUserInfo`, )
          .then(response => {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            this.$store.commit('setAuthUser', JSON.stringify(response.data.data));
          })
      },
      openCreateUserdialog() {
          this.createUserDialogVisible = true;
          var self = this;
          setTimeout(() => {
              // eslint-disable-next-line no-undef
              dawaAutocomplete.dawaAutocomplete(document.getElementById('dawa-autocomplete-input'), {
              select: function(selected) {
                  self.setDawaInfoOnModel(selected.data.postnr, selected.data.postnrnavn);
                $('#zipCode').val(selected.data.postnr);
                $('#city').val(selected.data.postnrnavn);
              }
          }, 200);
        });
      },
      setDawaInfoOnModel(zipCode, city) {
          this.createUserForm.zipCode = zipCode;
          this.createUserForm.city = city;
      },
      createUser() {
          this.createUserForm.streetnameAndNumber = $('#dawa-autocomplete-input').val();

          this.resetCreateUserFormValidation();
          var validationResult = this.validateCreateUserForm();

          var emailForBilling = this.createUserForm.emailAddress;

          if(this.createUserForm.emailForBilling != undefined && this.createUserForm.emailForBilling.length > 0)
          {
              emailForBilling = this.createUserForm.emailForBilling;
          }

          if(!validationResult) {
            this.createUserForm.loading = true;

            if(this.createUserForm.cvr === '')
                this.createUserForm.cvr = '00000000'; 

            if(this.createUserForm.companyName === '') 
                this.createUserForm.companyName = 'PRIVAT PERSON'
            
            axios.post(`${process.env.VUE_APP_BACKEND_URL}Auth/Register`, {
                "fullName": this.createUserForm.name,
                "sendEmail": true,
                "email": this.createUserForm.emailAddress,
                "useBillingEmail": this.createUserForm.useBillingEmail,
                "emailForBilling": emailForBilling,
                "cvr": parseInt(this.createUserForm.cvr),
                "phoneNumber": parseInt(this.createUserForm.phone),
                "password": this.createUserForm.password,
                "confirmPassword": this.createUserForm.confirmPassword,
                "acceptedConditions:": this.createUserForm.conditionsAccepted,
                "receiveNewsletters": this.createUserForm.receiveNewsletters,
                "companyName": this.createUserForm.companyName,
                "streetNameAndNumber": this.createUserForm.streetnameAndNumber,
                "zipCode": this.createUserForm.zipCode,
                "city": this.createUserForm.city
            })
            .then(response => {
                var data = response.data; 
                if(data.isSuccess)
                {
                    this.loginForm.email = this.createUserForm.emailAddress;
                    this.loginForm.password = this.createUserForm.password;
                    this.login();
                }
            }).catch(error => {
                this.createUserForm.loading = false;
                console.log("Register error response: ", error.response.data);
            });
          }
      },
      sendForgotPasswordEmail() {
          this.forgotPasswordForm.hideInput = true;
          axios.get(`${process.env.VUE_APP_BACKEND_URL}Auth/ForgotPassword?email=${this.forgotPasswordForm.email}`)
      },
      handleProfileNavigation(command) {
          console.log("command: " + command);
      },
      closeLoginDialog()
      {
          this.loginDialogVisible = false;
      },
      closeCreateDialog() {
          this.createUserDialogVisible = false;
      },
      resetCreateUserFormValidation() {
          this.createUserFormValidation.showNameError = false;
          this.createUserFormValidation.showCompanyNameError = false;
          this.createUserFormValidation.showCVRError = false;
          this.createUserFormValidation.showEmailAddressError = false;
          this.createUserFormValidation.showBillingAddressError = false;
          this.createUserFormValidation.showPasswordError = false;
          this.createUserFormValidation.showPasswordConfirmError = false;
          this.createUserFormValidation.showPasswordMisMatchError = false;
          this.resetCreateUserFormValidation.shopwConditionsAcceptedError = false;
          this.createUserFormValidation.showStreetnameAndNumberError = false;
          this.createUserFormValidation.showZipCodeError = false;
          this.createUserFormValidation.showCityError = false;
      },
      validateCreateUserForm() {
          var validationFailed = false;

          if(this.createUserForm.name.length === 0)
          {
              this.createUserFormValidation.showNameError = true;
              validationFailed = true;
          }
          if(this.createUserForm.companyName.length === 0 && !this.createUserForm.asPrivate)
          {
              this.createUserFormValidation.showCompanyNameError = true;
              validationFailed = true;
          }
          if(this.createUserForm.cvr.length !== 8 && !this.createUserForm.asPrivate)
          {
              this.createUserFormValidation.showCVRError = true;
              validationFailed = true;
          }
          if(this.createUserForm.streetnameAndNumber.length < 1)
          {
              this.createUserFormValidation.showStreetnameAndNumberError = true;
          }
          if(this.createUserForm.zipCode.length !== 4)
          {
              this.createUserFormValidation.showZipCodeError = true;
          }
          if(this.createUserForm.city.length < 1) 
          {
              this.createUserFormValidation.showCityError = true;
          }
          if(this.createUserForm.emailAddress.length === 0 || !this.validateEmail(this.createUserForm.emailAddress))
          {
              this.createUserFormValidation.showEmailAddressError = true;
              validationFailed = true;
          }
          if(this.createUserForm.useBillingEmail === true && (this.createUserForm.billingEmail.length === 0 || !this.validateEmail(this.createUserForm.billingEmail)))
          {
              this.createUserFormValidation.showBillingAddressError = true;
              validationFailed = true;
          }
          if(this.createUserForm.phone.length !== 8)
          {
              this.createUserFormValidation.showPhoneError = true;
              validationFailed = true;
          }
          if(this.createUserForm.password.length < 8 || !this.validatePassword(this.createUserForm.password))
          {
              this.createUserFormValidation.showPasswordError = true;
              validationFailed = true;
          }
          if(this.createUserForm.confirmPassword.length < 8 || !this.validatePassword(this.createUserForm.confirmPassword))
          {
              this.createUserFormValidation.showPasswordConfirmError = true;
              validationFailed = true;
          }
          else if(this.createUserForm.password !== this.createUserForm.confirmPassword)
          {
              this.createUserFormValidation.showPasswordMisMatchError = true;
              validationFailed = true;
          }

          if(this.createUserForm.conditionsAccepted === false)
          {
              this.createUserFormValidation.shopwConditionsAcceptedError = true;
              validationFailed = true;
          }

          return validationFailed;
          
      },
      validateEmail(email) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
      },
      validatePassword(password) {
        const re = /\d/;
        return re.test(password);
      },
      getBanner() {
        var query = qs.stringify({
              populate: '*'
          });
          axios.get(`${process.env.VUE_APP_STRAPI_API_URL}banner/?${query}`, )
          .then(response => {
              var banner = response.data.data.attributes.Content;
              var localStorageBanner = localStorage.getItem('banner-message');
              if(localStorageBanner !== undefined && localStorageBanner !== banner) {
                this.banner = banner;
              }

          })
      },
      closeBanner() {
        localStorage.setItem('banner-message', this.banner);
        this.banner = null;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@media only screen and (max-width: 1004px) {
    /* For mobile phones: */
    .nav-links .el-menu-item {
        font-size: 16px !important;
    }
}

.header {
    width: 100%;
    height: 60px;
    padding-top: 20px;
    top: 0;
    position: fixed;
    z-index: 999;
    background-color: #f9fafb;
    border-bottom: 1px solid rgb(204 204 204);
}

.banner-message {
    position: fixed;
    background-color: #64bfa4;
    height: 40px;
    z-index: 999;
    top: 0px;
    width: 100%;
    text-align: center;
}

.bannerSpacer {
    top: 40px !important;
}

.banner {
    margin: 0 auto;
}

.banner .banner-text a {
    color: #9433ff !important;
}

.banner .banner-text {
    color: white;
    padding-top: 5px;
    font-weight: 600;
    display: inline-block;
}

.banner .banner-close {
    display: inline; 
    margin-left: 10px;font-weight: 600;
    cursor: pointer;
    color: white;
}

#GoBackToHomePageBtn {
    width: 80%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 7%;
}

#GoBackToHomePageBtn img {
    width: 100%;
}

.logo-container img:hover {
    opacity: 0.7;
    transition: 0.2s;
}

.logo-container img {
    transition: 0.2s;
}

.logo-container img {
    max-width: 260px;
    width: 80%;
    margin-left: 40px;
    float: left;
    cursor: pointer;
    margin-top: 0 auto;
}

.el-menu {
    border-bottom: none !important;
    background-color: transparent !important;
}

.el-menu-item {
    color: grey;
    font-weight: 600;
    font-size: 18px;
    border-bottom: none !important;
    background-color: transparent !important;
    line-height: 50px !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
    color: #9433ff;
}


.el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none !important;
    background-color: transparent !important;
    color: #9433ff;
}

.account-buttons {
    text-align: end;
}

.input-info {
    float: left;
    width: 100%;
    font-size: 12px;
}

.input-error {
    float: left;
    width: 100%;
    font-size: 12px;
    color: rgb(255, 85, 85);
}

.login-footer {
    width: 100%;
    text-align: center;
}

.error-msg {
    color: red;
    padding-bottom: 20px;
}

.account-buttons {
    margin-right: 40px;
}

.account-buttons button {
    color: #9433ff;
    box-shadow: 0px 0px 7px -5px #000000;
}

.account-buttons button:hover {
    background-color: rgb(216 216 216 / 0%);
    border-color: #e6e6e6;
}

.loginDialog {
    width: 100px !important;
}

.login-dialog-links {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}

.login-dialog-links span {
    cursor: pointer;
}

.login-button {
    width: 100%;
    color: #9433ff;
    box-shadow: 0px 0px 7px -5px #000000;
}

.login-button:hover {
    border-color: #e6e6e6;
    color: #9433ff;
}

/* Phone navigation */
.phoneNavigation {
    width: 100%;
    background-color: #ffffff;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    color: rgb(75, 75, 75);
    border-top: 1px solid rgb(236, 236, 236);
}

.navigation {
    text-align: center;
    padding-top: 12px;
    display: grid !important;
}

.login svg {
    padding: 0px !important;
    font-size: 32px;
    margin-top: 10px;
    color: rgb(105, 105, 105);
}

.phoneNavigationLink {
    color: rgb(82, 81, 81) !important;
}

.phoneNavigationLinkIcon {
    float: left;
    width: 100%;
    height: 20px;
    font-size: 18px;
}

.phoneNavigationLinkIcon i {
    font-size: 25px !important;
}

.phoneNavigationLinkText {
    float: left;
    width: 100%;
}

.GoBackToHomePage {
    width: 100%;
    height: 20px;
    font-size: 22px;
    color: #9433ff;
}

.GoBackToHomePage div {
    margin-left: 20px;
    margin-top: 20px;
}

.el-link.el-link--default {
    vertical-align: baseline;
}

.hoverMenu {
    width: 30%;
    background-color: #ffffff;
    color: #464646;
    border-radius: 12px; 
    z-index: 9999;
    position: fixed;
    bottom: 80px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
}

.hoverMenu div {
    float: left;
    width: 100%;   
}

.hoverMenu .border-under {
    padding-top: 5px;
    border-bottom: 0.1px solid #e0e0e0;
}

.hoverMenu div:nth-child(2) {
    padding-top: 5px;    
}

.el-input-group__append, .el-input-group__prepend {
    width: 50px !important;
    max-width: 50px !important;
}

.linkToAgreement {
    color: #9433ff;
    cursor: pointer;
}

.linkToAgreement:hover {
    opacity: 0.7;
}


</style>
